import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';

interface ProfileContextType {
  profile: {
    avatarUrl: string;
    joinDate: string;
    totalPlayed: number;
    totalPlays: number;
    levelProgress: number;
    email: string;
    favoriteGame: string;
    hideUsername: boolean;
  };
  verifyEmail: () => void;
  changePassword: (currentPassword: string, newPassword: string) => void;
  changeEmail: (currentEmail: string, newEmail: string) => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const { session } = useAuth();
  const [profile, setProfile] = useState({
    avatarUrl: '/path/to/avatar.jpg',
    joinDate: '5/19/2024',
    totalPlayed: 0.00,
    totalPlays: 0,
    levelProgress: 0,
    email: 'bruh@bruh.com',
    favoriteGame: 'Minesweeper',
    hideUsername: false,
  });
  const changeEmail = (currentEmail: string, newEmail: string) => {
    toast.success('Email change request successful. Please verify on both emails.');
  }

  const verifyEmail = () => {
    // Implement email verification logic
    toast.success('Email change request successful. Please verify on both emails.');
  };

  const changePassword = (currentPassword: string, newPassword: string) => {
    toast.success('Password changed successfully');
  };
  const toggleHideUsername = async () => {
    const oldHideUsername = profile.hideUsername;
    setProfile({
      ...profile,
      hideUsername: !profile.hideUsername,
    });
    const { data, error } = await supabase.rpc('set_anon_status', { v_player_id: session?.user?.id, v_anonymous: !oldHideUsername });
    if (error) {
      toast.error(error.message);
      setProfile({
        ...profile,
        hideUsername: oldHideUsername,
      });
    }
  }
  useEffect(() => {
    const gameMapping: { [key: string]: string } = {
      games: "Blackjack",
      freebet_blackjack: "Free Bet Blackjack",
      ultimatetexas: "Ultimate Texas Hold'em",
      duelarena: "Duel Arena",
      dharoksdeathmatch: "Dharok's Deathmatch",
      roulette: "Roulette",
      dice: "Dice",
      plinko: "Plinko",
      flower_poker: "Flower Poker",
      mines: "Mines"
    };
    const getPlayerStats = async (player_id: string) => {
      const { data, error } = await supabase.rpc('player_stats', { v_player_id: player_id });
      if (error) {
        console.error('Error fetching player stats:', error.message);
      } else {
        const joinDate = new Date(session?.user?.created_at);
        const formattedJoinDate = new Intl.DateTimeFormat('en-US').format(joinDate);
        setProfile({
          ...profile,
          totalPlayed: data[0].tokens_played,
          totalPlays: data[0].games_played,
          favoriteGame: gameMapping[data[0].favorite_game] || data[0].favorite_game,
          joinDate: formattedJoinDate,
        });
      }
    }
    const getAnonymousStatus = async () => {
      const { data, error } = await supabase.rpc('get_anon_status', { v_player_id: session?.user?.id });
      if (error) {
        console.error('Error fetching user data:', error.message);
      } else {
        setProfile({
          ...profile,
          hideUsername: data,
        });
      }
    }
    if (session?.user?.created_at && session?.user?.id) {
      getPlayerStats(session?.user?.id);
      getAnonymousStatus();

    }
  }, [session]);

  return (
    <ProfileContext.Provider value={{ profile, verifyEmail, changePassword, changeEmail, toggleHideUsername }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};