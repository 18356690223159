import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import logo from '../assets/newlogomaybe2.png';
import PeopleIcon from '@mui/icons-material/People';
import { useMediaQuery, useTheme, Typography, Box, Button } from '@mui/material';
import RecentGamesTable from './RecentGamesDisplay/RecentGamesTable';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { keyframes } from '@emotion/react';
import LoginModal from "./LoginModal";
import background from '../assets/image_2.webp';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const EventBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { session } = useAuth();
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const handleButtonClick = () => {
    if (session) {
      // Scroll to silver leaderboards
      const leaderboardElement = document.getElementById('silver-leaderboard');
      if (leaderboardElement) {
        leaderboardElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Open login modal
      setLoginModalOpen(true);
    }
  };

  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };

  return (
    <>
      <Paper 
        elevation={3}
        sx={{
          background: `
                       linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
                       url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 75%',
          backgroundRepeat: 'no-repeat',
          borderRadius: '12px',
          color: 'hsla(39, 6.41%, 90%, 100%)',
          padding: isMobile ? '15px' : '20px 30px',
          position: 'relative',
          overflow: 'hidden',
          animation: `${fadeIn} 0.5s ease-out`,
          marginTop: isMobile ?'0px' : '24px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <EmojiEventsIcon sx={{ fontSize: isMobile ? 30 : 40, marginRight: '15px', color: 'hsl(156, 80%, 75%)' }} />
          <Typography 
            variant={isMobile ? "h6" : "h5"} 
            component="div" 
            sx={{ 
              fontWeight: 'bold', 
              textTransform: 'uppercase', 
              letterSpacing: '0.05em',
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
            }}
          >
            Weekly Free-Play Silver Leaderboard
          </Typography>
        </Box>
        <Typography 
          variant={isMobile ? "body2" : "body1"} 
          sx={{ 
            marginBottom: '10px',
            fontSize: isMobile ? '1rem' : '1.2rem',
            fontWeight: 'bold'
          }}
        >
          Play for free and win up to 500M!
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleButtonClick}
            sx={{
              backgroundColor: 'hsl(156, 80%, 75%)',  // Light blue color
              color: 'hsla(24, 12.82%, 15%, 1)',      // Dark text for contrast
              '&:hover': {
                backgroundColor: 'hsl(156, 80%, 65%)', // Slightly darker on hover
              },
            }}
          >
            {session ? "View Leaderboard" : "Register Now!"}
          </Button>
        </Box>
      </Paper>
      <LoginModal open={loginModalOpen} handleClose={handleCloseLoginModal} />
    </>
  );
};

function getRandomPlayerCount(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function SpacingGrid() {
  const [spacing, setSpacing] = React.useState(2);
  const [playerCounts, setPlayerCounts] = React.useState({
    blackjack: getRandomPlayerCount(22, 25),
    ultimateTexas: getRandomPlayerCount(17, 20),
    roulette: getRandomPlayerCount(16, 21),
    dice: getRandomPlayerCount(12, 15),
    plinko: getRandomPlayerCount(25, 31),
    duelArena: getRandomPlayerCount(11, 19),
    flowerPoker: getRandomPlayerCount(12, 15),
  });
  const { session, signedIn, loading } = useAuth();

  const theme = useTheme();
  const isMdOrSmaller = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px) and (orientation: portrait), (max-height:${theme.breakpoints.values.md}px) and (orientation: landscape)`);

  return (
    <div className="" style={{ minHeight: '100vh' }}>
      <img
        src={logo}
        alt="Logo"
        className="moving-logo"
        style={{
          position: "absolute",
          scale: '2',
          top: "50%",
          left: "5%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,
          opacity: 0.2
        }}
        draggable="false"
      />
      <EventBanner />
      <Grid className="md:p-20" sx={{ flexGrow: 1 }} container spacing={2} justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12} >
        <Grid key="blackjack-logo" item>
          <Link to="/blackjack">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/bj2.webp")',
                position: 'relative'
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.blackjack} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="freebet-logo" item>
          <Link to="/free-bet-blackjack">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/newfb.webp")',
                position: 'relative',
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.blackjack} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="ultimatetexas-logo" item>
          <Link to="/ultimate-texas">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/newutx.webp")',
                position: 'relative'
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.ultimateTexas} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>

        <Grid key="roulette-logo" item>
          <Link to="/roulette">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/roulette7.webp")',
                position: 'relative',
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.roulette} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="dice-logo" item>
          <Link to="/dice">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/dic2.webp")',
                position: 'relative'
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.dice} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="plinko-logo" item>
          <Link to="/plinko">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/plinkocropped.webp")',
                position: 'relative'
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.plinko} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>

        <Grid key="duelarena-logo" item>
          <Link to="/duel-arena">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/duel4.webp")',
                position: 'relative'
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.duelArena} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="dharoks-logo" item>
          <Link to="/dharoks">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/dhcropped2.webp")',
                position: 'relative'
                
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.duelArena} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="flowerpoker-logo" item>
          <Link to="/flower-poker">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/fpcropped3.webp")',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.flowerPoker} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="mines-logo" item>
          <Link to="/mines">
            <Paper
              className="game-card"
              style={{
                backgroundImage: 'url("/webplogos/mines2.webp")',
                position: 'relative',
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.flowerPoker} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
      </Grid>
      <div id="silver-leaderboard">
        <RecentGamesTable />
      </div>
    </div>
  );
}