import React, { useState } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useMediaQuery, useTheme, Menu, MenuItem, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRecentGames } from '../../hooks/useRecentGames.tsx';
import { useMyGames } from '../../hooks/useMyGames.tsx';
import { useHighRoller } from '../../hooks/useHighRoller.tsx';
import { useSilverLeaderboard } from '../../hooks/useSilverLeaderboard.tsx'; // New hook
import StyleIcon from "@mui/icons-material/Style";
import { TbCards } from 'react-icons/tb';
import { GiCardAceHearts, GiCartwheel, GiDaisy, GiBatteredAxe } from 'react-icons/gi';
import { LuSwords } from 'react-icons/lu';
import { FaDice, FaHockeyPuck } from 'react-icons/fa';
import { IoDiamond } from 'react-icons/io5';
import { GoldCoin, SilverCoin } from "../Coins.tsx";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ToggleMenu from './ToggleMenu.tsx';
import { FaTrophy } from 'react-icons/fa'; // Import trophy icon
import { styled } from '@mui/material/styles';
import { useCountdown } from '../../hooks/useCountdown'; // Import useCountdown hook
import { useAuth } from '../../contexts/AuthContext'; // Import the auth context

const gameIcons: { [key: string]: React.ElementType } = {
    'Blackjack': StyleIcon,
    'Free Bet Blackjack': TbCards,
    'Ultimate Texas Holdem': GiCardAceHearts,
    'Duel Arena': LuSwords,
    "Dharoks Deathmatch": GiBatteredAxe,
    'Roulette': GiCartwheel,
    'Dice': FaDice,
    'Plinko': FaHockeyPuck,
    'Flower Poker': GiDaisy,
    'Mines': IoDiamond,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: theme.palette.text.secondary, // This will be blue in most MUI themes
    backgroundColor: 'hsla(24, 12.82%, 20%, 100%)',
}));

const RecentGamesTable: React.FC = () => {
    const [tableView, setTableView] = useState<'Recent Games' | 'My Games' | 'High Roller' | 'Silver Leaderboards'>('Silver Leaderboards');
    const recentGames = useRecentGames();
    const myGames = useMyGames();
    const highRollerGames = useHighRoller();
    const silverLeaderboard = useSilverLeaderboard(); // New hook for silver leaderboard
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { days, hours, minutes } = useCountdown(); // Use useCountdown hook
    const { session } = useAuth(); // Get the current user from auth context

    const cellStyle = {
        fontSize: '1.1rem',
        color: '#fff',
    };

    const leftAlignStyle = {
        ...cellStyle,
        textAlign: 'left' as const,
    };

    const centerAlignStyle = {
        ...cellStyle,
        textAlign: 'center' as const,
    };

    const rightAlignStyle = {
        ...cellStyle,
        textAlign: 'right' as const,
    };

    const coinStyle = {
        marginLeft: '5px',
        marginBottom: '2px',
        height: '1em',
        width: '1em',
    };

    const anonymousIconStyle = {
        fontSize: '1em',
        verticalAlign: 'middle',
        marginRight: '5px',
    };

    const handleViewChange = (option: string) => {
        setTableView(option as 'Recent Games' | 'My Games' | 'High Roller' | 'Silver Leaderboards');
    };

    const tableContainerStyle = {
        maxWidth: '100%',
        overflowX: 'auto' as const,
        margin: 0,
    };

    const renderGameTable = () => {
        const rows = {
            'Recent Games': recentGames,
            'My Games': myGames,
            'High Roller': highRollerGames,
            'Silver Leaderboards': [],
        }[tableView] || [];

        return (
            <TableContainer
                className="game-container"
                component={Paper}
                style={tableContainerStyle}
            >
                <Table sx={{ minWidth: isMobile ? 300 : 650 }} aria-label="recent games table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Game</StyledTableCell>
                            {!isMobile && (
                                <>
                                    <StyledTableCell align="center">User</StyledTableCell>
                                    <StyledTableCell align="center">Time</StyledTableCell>
                                    <StyledTableCell align="right">Amount</StyledTableCell>
                                    <StyledTableCell align="right">Multiplier</StyledTableCell>
                                </>
                            )}
                            <StyledTableCell align="right">Result</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: index % 2 === 1 ? 'hsla(24, 12.82%, 20%, 100%)' : 'hsla(24, 12.82%, 25%, 100%)',
                                }}
                            >
                                <TableCell component="th" scope="row" style={leftAlignStyle}>
                                    <div style={{ display: 'flex', alignItems: 'right' }}>
                                        {gameIcons[row.game] && React.createElement(gameIcons[row.game], {
                                            style: {
                                                marginRight: '12px',
                                                fontSize: '1.4rem',
                                            }
                                        })}
                                        <span>{row.game}</span>
                                    </div>
                                </TableCell>
                                {!isMobile && (
                                    <>
                                        <TableCell style={centerAlignStyle}>
                                            {row.user === 'Hidden' ? (
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    <PersonOffIcon style={anonymousIconStyle} />
                                                    {row.user}
                                                </span>
                                            ) : (
                                                row.user
                                            )}
                                        </TableCell>
                                        <TableCell style={centerAlignStyle}>{row.time}</TableCell>
                                        <TableCell style={rightAlignStyle}>
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {row.amount}
                                                {row.type === 'gold' ? <GoldCoin style={coinStyle} /> : <SilverCoin style={coinStyle} />}
                                            </span>
                                        </TableCell>
                                        <TableCell style={rightAlignStyle}>{row.multiplier}</TableCell>
                                    </>
                                )}
                                <TableCell style={{
                                    ...rightAlignStyle,
                                    color: parseFloat(row.result) > 0 ? '#52c41a' : '#ff4d4f'
                                }}>
                                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                        {row.result}
                                        {row.type === 'gold' ? <GoldCoin style={coinStyle} /> : <SilverCoin style={coinStyle} />}
                                    </span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderSilverLeaderboard = () => {
        const topColors = [
            'hsla(45, 100%, 60%, 0.4)',  // Brighter Gold
            'hsla(0, 0%, 90%, 0.4)',     // Lighter Silver
            'hsla(30, 80%, 60%, 0.4)'    // Brighter Bronze
        ];
        const trophyColors = [
            'hsla(45, 100%, 50%, 1)',    // Vivid Gold
            'hsla(0, 0%, 80%, 1)',       // Bright Silver
            'hsla(30, 80%, 50%, 1)'      // Vivid Bronze
        ];
        const rewards = ['500', '250', '100'];

        return (
            <>
                <div className="countdown-timer">
                    Leaderboard resets in: {days}d {hours}h {minutes}m
                </div>
                <TableContainer
                    className="game-container"
                    component={Paper}
                    style={tableContainerStyle}
                >
                    <Table sx={{ minWidth: isMobile ? 300 : 650 }} aria-label="silver leaderboard table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Rank</StyledTableCell>
                                <StyledTableCell align="center">User</StyledTableCell>
                                <StyledTableCell align="right">Amount</StyledTableCell>
                                {!isMobile && <StyledTableCell align="right">Weekly Reward</StyledTableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {silverLeaderboard.map((row, index) => {
                                const isCurrentUser = row.username === session?.user?.user_metadata?.username;
                                const isTopThree = index < 3;

                                return (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            backgroundColor: isTopThree
                                                ? topColors[index]
                                                : index % 2 === 0
                                                ? 'hsla(24, 12.82%, 20%, 100%)'
                                                : 'hsla(24, 12.82%, 25%, 100%)',
                                        }}
                                    >
                                        <TableCell style={{
                                            ...leftAlignStyle,
                                            fontWeight: isTopThree ? 'bold' : 'normal',
                                            color: isTopThree ? trophyColors[index] : 'inherit'
                                        }}>
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {isTopThree && (
                                                    <FaTrophy 
                                                        style={{ 
                                                            marginRight: '8px',
                                                            color: trophyColors[index]
                                                        }} 
                                                    />
                                                )}
                                                {index + 1}
                                            </span>
                                        </TableCell>
                                        <TableCell style={{
                                            ...centerAlignStyle, 
                                            fontWeight: 'bold',
                                            textDecoration: isCurrentUser ? 'underline' : 'none'
                                        }}>
                                            {row.username === 'Hidden' ? (
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    <PersonOffIcon style={anonymousIconStyle} />
                                                    {row.username}
                                                </span>
                                            ) : (
                                                row.username
                                            )}
                                        </TableCell>
                                        <TableCell style={{...rightAlignStyle, fontWeight: isTopThree ? 'bold' : 'normal'}}>
                                            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                {row.silver_balance}
                                                <SilverCoin style={coinStyle} />
                                            </span>
                                        </TableCell>
                                        {!isMobile && (
                                            <TableCell style={{...rightAlignStyle, fontWeight: isTopThree ? 'bold' : 'normal'}}>
                                                {isTopThree ? (
                                                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        {rewards[index]}
                                                        <GoldCoin style={coinStyle} />
                                                    </span>
                                                ) : '-'}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option: string) => {
        handleViewChange(option);
        handleClose();
    };

    return (
        <div className="w-[95%] md:w-[80%] mx-auto">
            <div className="flex justify-center mt-4 mb-2">
                {isMobile ? (
                    <>
                        <Button
                            onClick={handleClick}
                            endIcon={<ExpandMoreIcon />}
                            variant="contained"
                            color="primary"
                        >
                            {tableView}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {['Recent Games', 'My Games', 'High Roller', 'Silver Leaderboards'].map((option) => (
                                <MenuItem
                                    key={option}
                                    onClick={() => handleMenuItemClick(option)}
                                    selected={tableView === option}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <ToggleMenu
                        options={['Recent Games', 'My Games', 'High Roller', 'Silver Leaderboards']}
                        selectedOption={tableView}
                        onOptionChange={handleViewChange}
                    />
                )}
            </div>
            {tableView === 'Silver Leaderboards' ? renderSilverLeaderboard() : renderGameTable()}
        </div>
    );
};

export default RecentGamesTable;