import React, { useState } from 'react';
import { Avatar, Button, Collapse, List, ListItem, ListItemText, Paper, Typography, Grid, IconButton, Box, Switch, FormControlLabel } from '@mui/material';
import { CalendarToday, Casino, SportsEsports, Favorite, Email, VpnKey, History } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { useProfile } from '../../contexts/ProfileContext';
import { usePayments } from '../../hooks/useExchangeHistory';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePasswordModal from './ChangePasswordModal';

const ProfilePage: React.FC = () => {
    const { session } = useAuth();
    const { profile, verifyEmail, changePassword, changeEmail, toggleHideUsername } = useProfile();
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const exchangeHistory = usePayments();

    const handleEmailSave = (currentEmail: string, newEmail: string) => {
        // Implement email change logic
        changeEmail(currentEmail, newEmail);
    };

    const handlePasswordSave = (currentPassword: string, newPassword: string) => {
        changePassword(currentPassword, newPassword);
    };

    // Remove this function as it's no longer needed:
    // const handleProfilePictureUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // Implement profile picture upload logic
    // };

    // const exchangeHistory = [
    //     { date: '2023-01-01', details: 'Exchanged 100 tokens for $10' },
    //     { date: '2023-02-01', details: 'Exchanged 200 tokens for $20' },
    //     // Add more history items as needed
    // ];

    return (
        <Paper className="game-container" elevation={3} sx={{ background: 'radial-gradient(circle at center, hsla(24, 12.82%, 25%, 100%), hsla(24, 12.82%, 15%, 100%))', minHeight: '100vh', maxWidth: 'md', mx: 'auto', mt: 12, p: 4, borderRadius: 2 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>{session?.user?.user_metadata?.username}</Typography>
                    <Typography variant="subtitle1" color="text.secondary">{session?.user?.email}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 'bold' }}>Profile Information</Typography>
                    <Grid container spacing={1} sx={{ background: 'radial-gradient(circle at center, hsla(24, 12.82%, 20%, 100%), hsla(24, 12.82%, 15%, 100%))', px: 3, pt: 3, pb: 2, borderRadius: 3 }}>
                        {[
                            { label: 'Member since', value: profile.joinDate, icon: <CalendarToday /> },
                            { label: 'Tokens played', value: profile.totalPlayed, icon: <Casino /> },
                            { label: 'Games played', value: profile.totalPlays, icon: <SportsEsports /> },
                            { label: 'Favorite Game', value: profile.favoriteGame, icon: <Favorite /> },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} key={index} sx={{ mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {React.cloneElement(item.icon, { sx: { fontSize: '1.5rem' } })}
                                        <Typography variant="body1" sx={{ ml: 1, fontSize: '1.1rem' }}>{item.label}</Typography>
                                    </Box>
                                    <Typography variant="body1" fontWeight="bold" sx={{ fontSize: '1.1rem' }}>{item.value}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 2, fontWeight: 'bold' }}>Account Settings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" fullWidth onClick={() => setEmailModalOpen(true)} sx={{ bgcolor: 'secondary.main' }} startIcon={<Email />}>
                                Change Email
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" fullWidth onClick={() => setPasswordModalOpen(true)} sx={{ bgcolor: 'secondary.main' }} startIcon={<VpnKey />}>
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 2, fontWeight: 'bold' }}>Privacy Settings</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={profile.hideUsername}
                                onChange={toggleHideUsername}
                                color="primary"
                            />
                        }
                        label="Hide username on leaderboards"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setHistoryOpen(!historyOpen)}
                        color="info"
                        startIcon={<History />}
                    >
                        {historyOpen ? 'Hide Exchange History' : 'Show Exchange History'}
                    </Button>
                    <Collapse in={historyOpen}>
                        <List>
                            {exchangeHistory.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText
                                        primary={
                                            <span className="text-gray-300">
                                                {item.created_at} - Status: <span className={`font-bold ${item.status === 'completed' ? 'text-green-500' : 'text-yellow-500'}`}>{item.status}</span>
                                            </span>
                                        }
                                        secondary={
                                            <>
                                                <span className="text-gray-400">
                                                    Exchanged ${item.amountusd} for {item.coins} coins
                                                </span>
                                                <br />
                                                <span className="text-gray-400">
                                                    Invoice ID: {' '}
                                                    <a
                                                        href={item.invoice_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-400 hover:text-blue-300 underline"
                                                    >
                                                        {item.invoice_id}
                                                    </a>
                                                </span>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </Grid>
            </Grid>
            <ChangeEmailModal
                open={emailModalOpen}
                onClose={() => setEmailModalOpen(false)}
                onSave={handleEmailSave}
            />
            <ChangePasswordModal
                open={passwordModalOpen}
                onClose={() => setPasswordModalOpen(false)}
                onSave={handlePasswordSave}
            />
        </Paper >
    );
};

export default ProfilePage;