import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, IconButton } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';

interface CryptoTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const CryptoTransactionModal: React.FC<CryptoTransactionModalProps> = ({ open, onClose, type }) => {
    const [amount, setAmount] = useState('');
    const [isUSD, setIsUSD] = useState(true);
    const tokenPrice = 0.20; // Price of one Gold token in USD
    const { session } = useAuth();

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value);
    };

    const handleSwap = () => {
        setIsUSD(!isUSD);
        setAmount(''); // Reset amount when swapping
    };

    const calculateConversion = () => {
        if (amount === '') return '0';
        const numAmount = parseFloat(amount);
        return isUSD
            ? (numAmount / tokenPrice).toFixed(2)
            : (numAmount * tokenPrice).toFixed(2);
    };

    const handleSubmit = async () => {
        try {
            const usdAmount = isUSD ? parseFloat(amount) : parseFloat(calculateConversion());
            const { data, error } = await supabase.functions.invoke('create-invoice', {
                body: { amount: usdAmount, userId: session.user.id },
            });
            if (data && data.paymentUrl) {
                window.open(data.paymentUrl, '_blank');
                onClose();
                window.location.href = '/exchanges?view=crypto';
            }else{
                console.log('error', error)
                throw error;
            }
    
        } catch (error) {
            console.error('Error creating invoice:', error);
            toast.error('An error occurred while creating the invoice. Enter valid amount between 7 and 5000 USD');
            // Don't close the modal if there's an error
        }
    };

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white', // This is the default unfocused label color
        },
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    {type === 'deposit' ? 'Deposit Crypto' : 'Withdraw Crypto'}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        fullWidth
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        label={isUSD ? 'Amount in USD' : 'Amount in Gold tokens'}
                        variant="outlined"
                        sx={textFieldSx}
                    />
                    <IconButton onClick={handleSwap} sx={{ ml: 1 }}>
                        <SwapHorizIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {isUSD
                        ? <>${amount || '0'} USD = {calculateConversion()} <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: '20px', height: '20px' }} /> </>
                        : (
                            <>
                                {amount || '0'} <GoldCoin additionalStyles="mx-1 mb-1" style={{ width: '20px', height: '20px' }} /> = ${calculateConversion()} USD
                            </>
                        )
                    }
                </Typography>
                <div className='flex items-center'>
                    <GoldCoin additionalStyles='mr-2' />
                    <Typography variant="body2">
                        <span className='text-xl'> = $0.20</span>
                    </Typography>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        {type === 'deposit' ? 'Deposit' : 'Withdraw'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CryptoTransactionModal;